app.controller('HomeController', ['$scope','$http','Config','$rootScope','toaster', function($scope,$http,Config,$rootScope,toaster){

	$scope.email = '';

	$scope.save = function(form){
		if(form.$valid){
			$rootScope.load = true;
			//send an ajax call to save the message and send an email notification
			data = {
					'email':form.email.$modelValue,
					'brand':Config.brand_key
				};

			$http({
			  method: 'POST',
			  data:data,
			  url: Config.api_url+'newsletter?discount-type=percentage&discount-value=10',
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
			   	toaster.pop('success', "Subscribed Successfully!", 'We added your email to our mailing list.');
			   	form.$setPristine();
			   	$scope.email = '';
			   	form.$setUntouched();
			   	$rootScope.load = false;
			  }, function(response) {
			    console.log(response);
			 });
		}
	}
}])